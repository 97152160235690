
const statusHolder = (success = true, message = "") => {
  return {
    success,
    message
  }
}

export {
  statusHolder
}
