<template>
    <div id="service_page">
        <PageHeader />
        <div class="container">
            <div class="brief-description">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="left-side">
                            <h1>Web Hosting &amp; Domain Registration</h1>
                            <h4>
                                Let us find <strong>the right solution</strong>
                                for you.
                            </h4>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="right-side">
                            <div class="services-nav">
                                <ul class="list-unstyled">
                                    <li><button class="btn active-btn">Web Hosting &amp; Domain Registration</button>
                                    </li>
                                    <li><button class="btn">Telecoms</button></li>
                                    <li><button class="btn">Network Solution Services</button></li>
                                    <li><button class="btn">IT Solution Services</button></li>
                                    <li><button class="btn">Branding</button></li>
                                    <li><button class="btn">Web Design &amp; Development</button></li>

                                    <li><button class="btn">App &amp; Software Development</button></li>

                                    <li><button class="btn">Computer Sales &amp; Repairs</button></li>
                                    <li><button class="btn">Company Registration Packages</button></li>
                                </ul>
                            </div>

                            <router-link to="/contact-us">Connect with us
                                <i class="fas fa-arrow-circle-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="web-hosting">
                <div class="service-defination">
                    <div class="row">
                        <div class="col-sm-6 w3-center">
                            <img src="../assets/img/web-hosting.png" alt="">
                        </div>
                        <div class="col-sm-6">
                            <h3 class="page-title">Quality Web &amp; Email hosting</h3>

                            <p>
                                It all starts with a domain; our Shared Website Hosting solutions are the perfect
                                solution
                                for your personal blog or website. Each shared hosting account is fine-tuned to offer
                                your
                                site the very best in hosted performance and reliability.
                            </p>

                            <p>
                                We are here to make sure your website <strong>stays up</strong>, <strong>stays
                                    fast</strong> and <strong>stays supported</strong>.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="service-plans">
                    <div class="plan-heading">
                        <h3>Web &amp; Email Hosting Plans</h3>
                        <p>We offer affordable quality web &amp; email hosting solution for your business. checkout our
                            hosting plans.</p>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="plan w3-card">
                                <h4>Starter Plan</h4>
                                <ul class="">
                                    <li><strong>1GB</strong> Storage</li>
                                    <li><strong>Unlimited</strong> bandwidth/Traffic</li>
                                    <li><strong>10 x</strong> Email Accounts</li>
                                    <li><strong>Host</strong> up to 1 Website</li>
                                    <li><strong>Free SSL</strong> Certificate</li>
                                </ul>
                                <h2>R120 <span>/month</span></h2>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="plan w3-card">
                                <h4>Mega Plan</h4>
                                <ul class="">
                                    <li><strong>4GB</strong> Storage</li>
                                    <li><strong>Unlimited</strong> bandwidth/Traffic</li>
                                    <li><strong>25 x</strong> Email Accounts</li>
                                    <li><strong>Host</strong> up to 5 Websites</li>
                                    <li><strong>Free SSL</strong> Certificate</li>
                                </ul>
                                <h2>R230 <span>/month</span></h2>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="plan w3-card">
                                <h4>Ultra Plan</h4>
                                <ul class="">
                                    <li><strong>10GB</strong> Storage</li>
                                    <li><strong>Unlimited</strong> bandwidth/Traffic</li>
                                    <li><strong>100 x</strong> Email Accounts</li>
                                    <li><strong>Host</strong> up to 15 Websites</li>
                                    <li><strong>Free SSL</strong> Certificate</li>
                                </ul>
                                <h2>R360 <span>/month</span></h2>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="plan">
                                <h4>Ultra Plus Plan</h4>
                                <ul class="">
                                    <li><strong>20GB</strong> Storage</li>
                                    <li><strong>Unlimited</strong> bandwidth/Traffic</li>
                                    <li><strong>Unlimited x</strong> Email Accounts</li>
                                    <li><strong>Unlimited</strong> Websites</li>
                                    <li><strong>Free SSL</strong> Certificate</li>
                                </ul>
                                <h2>R490 <span>/month</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <newsletter />
    </div>
</template>

<script>
    import PageHeader from "../components/fragments/PageHeader.vue";
    import Newsletter from "../components/fragments/Newsletter.vue";
    export default {
        components: {
            PageHeader,
            Newsletter,
        },
    };
</script>