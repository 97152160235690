import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Contact from '@/views/Contact.vue'
import Services from '@/views/Services.vue'
import WebHosting from '@/views/services/Web_hosting.vue';
import WebDesign from '@/views/services/Web_design.vue';
import ITSolutions from '@/views/services/IT_solutions.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'About',
    component: About,
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/our-services',
    name: 'Services',
    component: Services,
  },
  {
    path: '/web-and-email-hosting',
    name: 'Web-Hosting',
    component: WebHosting,
  },
  {
    path: '/web-design-and-development',
    name: 'Web-Design',
    component: WebDesign,
  },
  {
    path: '/it-solutions-and-services',
    name: 'IT-Solutions',
    component: ITSolutions,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
