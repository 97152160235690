<template>
    <div class="top-footer">
        <div class="container">
            <div class="row">
                <div class="col-sm-3">
                    <h4>Applications</h4>
                    <ul class="list-unstyled">
                        <li>
                            <router-link to="">
                                Microsoft Products
                            </router-link>
                        </li>
                        <li>
                            <router-link to=""> Avast Antivirus </router-link>
                        </li>
                        <li>
                            <router-link to="">
                                Adobe Acrobat Reader DC
                            </router-link>
                        </li>
                        <li>
                            <router-link to=""> Many Apps </router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-3">
                    <h4>Services</h4>
                    <ul class="list-unstyled">
                        <li>
                            <router-link to="/web-and-email-hosting">
                                Web Hosting &amp; Domain
                                Registration</router-link>
                        </li>
                        <li>
                            <router-link to="">
                                Network Services &amp; Solutions
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/it-solutions-and-services">
                                IT Solutions &amp; Services
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/web-design-and-development">
                                Web Design &amp; Development
                            </router-link>
                        </li>
                        <li>
                            <router-link to=""> Telecoms </router-link>
                        </li>
                        <li>
                            <router-link to="">
                                App &amp; Software development
                            </router-link>
                        </li>
                        <li>
                            <router-link to="">
                                Computer Sales &amp; Repairs
                            </router-link>
                        </li>
                        <li>
                            <router-link to="">
                                Company Registration Packages
                            </router-link>
                        </li>
                        <li>
                            <router-link to=""> Branding </router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-3">
                    <h4>In Office Services</h4>
                    <ul class="list-unstyled">
                        <li>
                            <router-link to="">Photocoying and Printing</router-link>
                        </li>
                        <li>
                            <router-link to="">Computer Accessories</router-link>
                        </li>
                    </ul>

                    <h4>Support</h4>
                    <ul class="list-unstyled">
                        <li>
                            <router-link to="/contact-us">Support</router-link>
                        </li>
                        <li>
                            <router-link to="/contact-us">Contact</router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-3">
                    <h4>Products</h4>
                    <ul class="list-unstyled">
                        <li>
                            <a href="https://infinitespace.co.za">Infinite Space</a>
                        </li>
                        <li>
                            <a href="#">Infinite Data</a>
                        </li>
                        <li>
                            <a href="#">Infinite Data-POS</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div id="footer">
        <div class="content">
            <div class="social">
                <p>
                    Connect with us <span></span>
                    <a :href="socialLinks.Facebook" target="_blank" rel="noopener noreferrer">
                        Fb
                    </a>

                    <a :href="socialLinks.Twitter" target="_blank" rel="noopener noreferrer">
                        Tw
                    </a>

                    <a :href="socialLinks.Instagram" target="_blank" rel="noopener noreferrer">
                        In
                    </a>
                </p>
            </div>
            <div class="copyright">
                <p>&copy; {{ currentYear }} All rights reserved.</p>
            </div>
        </div>
    </div>
</template>
<script>
    import { ref } from "vue";
    import socialLinks from "../../data/socialLinks.json";
    export default {
        setup() {
            const currentYear = ref(new Date().getFullYear());
            return {
                socialLinks,
                currentYear,
            };
        },
    };
</script>