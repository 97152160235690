<template>
    <div id="hero_header" v-if="header != null">
        <div class="white-bg-overlay">
            <div class="header-text">
                <h1 v-if="header.title != ''">{{header.title}}</h1>
                <h3 v-if="header.subTitle">{{header.subTitle}}</h3>
                <h4 v-if="header.description">{{header.description}}</h4>
            </div>
        </div>
    </div>
</template>

<script>
import Navigation from "./Navigation.vue";
import router from '../../router'
import pageHeader from '../../data/pageHeader.json'
import { reactive, watch, ref } from 'vue';
export default {
    components: {
        Navigation,
    },
    setup() {
        const routerName = ref(router.currentRoute.value.name)
        const header = reactive({
            title: '',
            subTitle: '',
            description: ''
        })
        watch(router.currentRoute, (to) => {
            routerName.value = to.name
            switch(routerName.value) {
                case 'About':
                case 'Services':
                case 'Products':
                case 'Contact':
                    routerChange()
                break
            }
        })
        routerChange() 
        function routerChange() {
            const {title, subTitle, description } = pageHeader[routerName.value]
            header.title = title
            header.subTitle = subTitle
            header.description = description 
        }
        return {
            header
        }
    }
};
</script>