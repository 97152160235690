<template>
    <div class="signup-newsletter">
        <div class="row">
            <div class="col-sm-10 mx-auto">
                <div class="content">
                    <div>
                        <h2>
                            Sign up to receive our <br />
                            <span>newsletter</span>
                        </h2>
                    </div>
                    <div>
                        <div class="form-group m-0">
                            <input
                                type="email"
                                v-model="email"
                                placeholder="Email"
                                aria-placeholder="Email"
                                title="Enter your email address"
                            />
                            <button type="button" @click.prevent="subscribe()">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import { validEmail } from '../../utils/validation'
import { useStore } from 'vuex';
export default {
    setup() {
        const email = ref('');
        const store = useStore()

        async function subscribe() {
            const { success, message } = validEmail(email.value)
            if (!success) {
                console.log(message);
                return
            }
            
            const formData = new FormData()
            formData.append('email', email.value)
            const res = await store.dispatch('client/subscribe', formData)
            console.log(res);
        }

        return {
            email,
            subscribe
        }
    }
}
</script>