<template>
    <div id="home_page">
        <div class="home-hero">
            <div class="hero-overlay">
                <div class="intro">
                    <h1>Cutting-edge ICT Service Solutions and Products.</h1>
                    <p>
                        Creating beautiful digital products engineered to drive
                        growth while you focus on what's important.
                    </p>
                    <button type="button" class="w3-card">Get Pricing</button>
                </div>
                <div class="easy-contact">
                    <div class="contact-container">
                        <h4 class="sub-title">Get In Touch</h4>
                        <div class="contact-ways">
                            <div class="phone">
                                <i class="fa fa-phone-alt"></i>
                                <h4>
                                    <a href="tel:+27397272624">039 727 2624</a> |
                                    <a href="tel:+27397272036">039 727 2036</a>
                                </h4>
                            </div>
                            <div class="address">
                                <i class="fa fa-map-marker-alt"></i>
                                <h4>
                                    92 Main Street, Shop No. 6,<br />
                                    Gregnik Properties CC,<br />
                                    Kokstad, 4700
                                </h4>
                            </div>
                            <div class="contact-btn">
                                <router-link to="/contact-us">Contact Us</router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="hero-footer">
                    <ul class="list-unstyled social-icons">
                        <li>
                            <a :href="socialLinks.Facebook" target="_blank" rel="noopener noreferrer" class="nav-link">
                                FACEBOOK
                            </a>
                        </li>

                        <li>
                            <a :href="socialLinks.Twitter" target="_blank" rel="noopener noreferrer" class="nav-link">
                                TWITTER
                            </a>
                        </li>

                        <li>
                            <a :href="socialLinks.Instagram" target="_blank" rel="noopener noreferrer" class="nav-link">
                                INSTAGRAM
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="home-about">
            <div class="text-side">
                <h1>
                    What makes Infinito Group special?
                </h1>
                <p>
                    We think differently while we provide you with our tailor-made solutions to your business.
                </p>
                <p>
                    Infinito adapts, responds, and moves faster to address any IT and Telecoms challenges to your
                    business; that is what defines us from the rest.
                </p>

                <p>
                    Our software development division is empowered with advanced skills to deploy any kind of solution
                    according to your needs, and most importantly to help you make your business decision and governance
                    more easier.
                </p>

                <div class="quick-btns">
                    <router-link to="/about-us" class="bordered-btn">
                        More About Us <i class="fa fa-chevron-right"></i>
                    </router-link>
                    <router-link to="/contact-us" class="main-btn">
                        Contact Us
                    </router-link>
                </div>
            </div>
            <div class="img-side">
                <img src="../assets/img/logo2.png" alt="About Skills" />
            </div>
        </div>
        <div class="home-partners" v-if="partners.length > 0">
            <div v-for="{ image, alt } in partners" :key="image">
                <img :src="require(`../assets/img/Partners/${image}`)" :alt="alt" />
            </div>
        </div>
    </div>
</template>

<script>
    import partnersData from "../data/partners.json";
    import socialLinks from "../data/socialLinks.json";
    import { ref } from "vue";
    export default {
        setup() {
            const partners = ref([...partnersData]);
            return {
                partners,
                socialLinks,
            };
        },
    };
</script>