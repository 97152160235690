import {
  createApp
} from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import $ from 'jquery'
import 'popper.js'
import 'bootstrap'
// import vWow from 'vue-wow'
import './assets/scss/main.scss'

window.$ = $
createApp(App).use(store).use(router).mount('#app')