<template>
    <div id="contact_page">
        <PageHeader />
        <div class="container">
            <div class="brief-description">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="left-side">
                            <h1>Get in touch with us now</h1>
                            <h4>
                                Let us find <strong>the right solution</strong>
                                for you.
                            </h4>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="right-side">
                            <p>
                                At Infinito Group, we offer infinite services - Web Design &amp; Development, Domain
                                Registration &amp; Hosting, Branding, IT Service Solutions, Network Service Solutions,
                                Telecommunications, Computer Sales and Repairs, App Software &amp; Development, and
                                Printer Sales.
                            </p>

                            <router-link to="/about-us">More about us
                                <i class="fas fa-arrow-circle-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <contact-form />
        <div class=""></div>
        <div class="company-location">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6873.826331182784!2d29.42067607701851!3d-30.548031380147044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ef5a070be687cbf%3A0x823e740e6134921f!2s92%20Main%20St%2C%20Kokstad%2C%204700!5e0!3m2!1sen!2sza!4v1617798860102!5m2!1sen!2sza"
                width="100%" height="100%" style="border: 0" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <newsletter />
    </div>
</template>

<script>
    import PageHeader from "../components/fragments/PageHeader";
    import ContactForm from "../components/fragments/ContactForm";
    import Newsletter from "../components/fragments/Newsletter.vue";
    export default {
        components: {
            PageHeader,
            ContactForm,
            Newsletter,
        },
    };
</script>