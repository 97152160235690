import { statusHolder } from './helper'

const validPassword = ( password ) => {
  if ( password.length == 0 ) {
    return {
      success: false,
      message: 'Password is required' 
    }
  }

  if ( password.length < 6 ) {
    return {
      success: false,
      message: 'Password should be 6 digits' 
    }
  }

  return { success: true}
}

const validAddress = ( address ) => {
  if ( address.length < 6 ) {
    return statusHolder(false, 'Address should be at least 6 characters')
  }

  return statusHolder()
}

const validName = ( name ) => {
  if ( name.length < 3 ) {
    return statusHolder(false, 'Name should be at least 3 characters')
  }

  return statusHolder()
}

const validEmail = ( email ) => {
   // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if ( email.length < 5 ) {
    return statusHolder(false, 'Email should be at least 5 characters')
  } else if ( !re.test( email.toLowerCase() ) ) {
    return statusHolder(false, 'Please provide a valid email address')
  }
  return statusHolder();
}
const validPhone =( phone ) => {
  if ( phone.length < 10 ) {
    return statusHolder(false, 'Contact number should be at least 10 digits')
  }

  return statusHolder()
}

const validFile = ( { name, size } ) => {
  const nameArr = name.split( '.' )
  const fileExtension = nameArr[nameArr.length - 1]
    let fileExtArray = [
        'png', 'jpg', 'gif', 'mp4', 'webm', 'mpg', 'ogg', 'avi', 'mov', 'pdf', 'rtf', 'txt'
    ]
    let cleanFileExtension = fileExtension.toLowerCase()

  if (!fileExtArray.includes(cleanFileExtension) && !cleanFileExtension.startsWith('doc')
  && !cleanFileExtension.startsWith('dot') && !cleanFileExtension.startsWith('xls')
  && !cleanFileExtension.startsWith('xlt') && !cleanFileExtension.startsWith('xlw')
  && !cleanFileExtension.startsWith('pp') && !cleanFileExtension.startsWith('po')) {
        return statusHolder(false, 'File format is not supported')
  }
  
  if ( size > 50000000 ) {
    return statusHolder( false, 'File should not be larger than 50Mb')
  }
    return statusHolder()
}

export {
  validPassword,
  validAddress,
  validName,
  validPhone,
  validFile,
  validEmail
}