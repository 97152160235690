<template>
    <div id="about">
        <PageHeader />
        <div class="container">
            <div class="brief-description">
                <div class="row">
                    <div class="col-sm-12 mx-auto">
                        <p>
                            Infinito Group was founded in 2019 as MDAP Holdings and we’ve been building
                            relationships with clients, helping them save time and money without the need to employ
                            their own IT team. We have grown since then and we now offer a wide range of IT
                            solutions to an industry like yours.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="vision-mission">
            <div class="container">
                <div class="vision">
                    <div class="row">
                        <div class="col-sm-6">
                            <img src="../assets/img/vision.jpg" alt="" />
                        </div>
                        <div class="col-sm-6">
                            <h1>Our vision</h1>
                            <h4>
                                To create a reliable, sustainable and cost-effective ICT environment for our clients.
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="mission">
                    <div class="row">
                        <div class="col-sm-6 w3-hide-large w3-hide-medium">
                            <img src="../assets/img/mission.jpg" alt="" />
                        </div>
                        <div class="col-sm-6">
                            <h1>The mission we are on</h1>
                            <h4>
                                We delve to create value while we commit a permanent solution to your ICT problem.
                            </h4>
                        </div>
                        <div class="col-sm-6 w3-hide-small">
                            <img src="../assets/img/mission.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <newsletter />
    </div>
</template>

<script>
    import PageHeader from "../components/fragments/PageHeader.vue";
    import Newsletter from "../components/fragments/Newsletter.vue";
    export default {
        components: {
            PageHeader,
            Newsletter,
        },
    };
</script>