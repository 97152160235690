<template>
    <nav class="navbar navbar-expand-sm" id="navigation_bar">
        <!-- Brand -->
        <router-link class="navbar-brand" to="/">
            <img src="../../assets/img/Logo.png" class="w3-hide-small" alt="" />
            <img src="../../assets/img/Logo.png" class="w3-hide-large w3-hide-medium" alt="" />
        </router-link>

        <!-- Toggler/collapsibe Button -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <i class="fa fa-bars"></i>
        </button>

        <!-- Navbar links -->
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <router-link class="nav-link" to="/">Home</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/about-us">About Us</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link nito-dropdown" to="/our-services">
                        Services <i class="fa fa-chevron-down"></i></router-link>
                    <ul class="nito-dropdown-menu list-unstyled">
                        <li>
                            <router-link to="/web-and-email-hosting">
                                Web Hosting &amp; Domain
                                Registration</router-link>
                        </li>
                        <li>
                            <router-link to="">
                                Network Services &amp; Solutions
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/it-solutions-and-services">
                                IT Solutions &amp; Services
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/web-design-and-development">
                                Web Design &amp; Development
                            </router-link>
                        </li>
                        <li>
                            <router-link to=""> Telecoms </router-link>
                        </li>
                        <li>
                            <router-link to="">
                                App &amp; Software development
                            </router-link>
                        </li>
                        <li>
                            <router-link to="">
                                Computer Sales &amp; Repairs
                            </router-link>
                        </li>
                        <li>
                            <router-link to="">
                                Company Registration Packages
                            </router-link>
                        </li>
                        <li>
                            <router-link to=""> Branding </router-link>
                        </li>
                    </ul>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">How we work</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Products</a>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/contact-us">Contact</router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>