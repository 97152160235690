<template>
    <div class="contact-form">
        <div class="bg-overlay">
            <div class="container">
                <div class="row">
                    <div class="col-sm-5">
                        <div class="contact-details">
                            <div class="item">
                                <h4>Come say hi</h4>
                                <p>
                                    92 Main Street, Shop No. 6,<br />
                                    Kokstad, Kwa-Zulu Natal, <br />
                                    4700
                                </p>
                            </div>

                            <div class="item">
                                <h4>Office hours</h4>
                                <p>
                                    Monday &#8212; Friday <br />
                                    8am &#8212; 5pm <br />
                                    Saturday <br />
                                    9am &#8212; 2pm <br />
                                </p>
                            </div>

                            <div class="item">
                                <h4>Support</h4>
                                <p>
                                    <a href="mailto:info@infinitogroup.co.za">info@infinitogroup.co.za</a>
                                    <br />
                                    <a href="tel:+27397272624">0397272624</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-7">
                        <form @submit.prevent="sendEmail" method="post" autocomplete="off">
                            <h4>
                                Leave a message |
                                <span>And we'll get back to you</span>
                            </h4>
                            <input type="text" v-model="form.name" placeholder="Full Name (e.g. John Doe)"
                                aria-placeholder="Full Name (e.g. John Doe)" required />
                            <input type="email" v-model="form.email" placeholder="Email" aria-placeholder="Email"
                                required />
                            <input type="number" v-model="form.contact_number" placeholder="Contact Number"
                                aria-placeholder="Contact Number" required />
                            <textarea v-model="form.message" placeholder="Message" aria-placeholder="Message"
                                required></textarea>

                            <div class="text-right">
                                <button type="submit">Send Message</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { reactive } from "vue";
    import { useStore } from "vuex";
    import _ from "lodash";
    export default {
        setup() {
            const store = useStore();
            const form = reactive({
                name: "",
                email: "",
                contact_number: "",
                message: "",
            });

            async function sendEmail() {
                const data = new FormData();
                data.append("name", form.name);
                data.append("email", form.email);
                data.append("contact_number", form.contact_number);
                data.append("message", form.message);
                const { success, message } = await store.dispatch(
                    "client/sendEmail",
                    data
                );
                if (success) {
                    form.name = form.email = form.contact_number = form.message =
                        "";
                }
            }

            return {
                form,
                sendEmail,
            };
        },
    };
</script>

<style>
</style>