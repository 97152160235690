<template>
    <div id="service_page">
        <PageHeader />
        <div class="container">
            <div class="brief-description">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="left-side">
                            <h1>Web Design &amp; Development</h1>
                            <h4>
                                Let us find <strong>the right solution</strong>
                                for you.
                            </h4>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="right-side">
                            <div class="services-nav">
                                <ul class="list-unstyled">
                                    <li><button class="btn">Web Hosting &amp; Domain Registration</button>
                                    </li>
                                    <li><button class="btn">Telecoms</button></li>
                                    <li><button class="btn">Network Solution Services</button></li>
                                    <li><button class="btn">IT Solution Services</button></li>
                                    <li><button class="btn">Branding</button></li>
                                    <li><button class="btn active-btn">Web Design &amp; Development</button></li>

                                    <li><button class="btn">App &amp; Software Development</button></li>

                                    <li><button class="btn">Computer Sales &amp; Repairs</button></li>
                                    <li><button class="btn">Company Registration Packages</button></li>
                                </ul>
                            </div>

                            <router-link to="/contact-us">Connect with us
                                <i class="fas fa-arrow-circle-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="web-design">
                <div class="service-defination">
                    <div class="row">
                        <div class="col-sm-6 w3-center">
                            <img src="../../assets/img/web-design.jpg" alt="">
                        </div>
                        <div class="col-sm-6">
                            <h3 class="page-title">Affordable Website Packages</h3>

                            <p>
                                A website makes it real; Imagine a sales representative that worked for you 24/7 that
                                never got sick, never needed time off and never complained. Wouldn’t that just be great?
                            </p>

                            <p>
                                Well, that’s exactly what your website should be for your company, and the best part
                                about it all? We can build it for you and it costs less than having to employ you own
                                developer.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="service-plans">
                    <div class="plan-heading">
                        <h3>Web Design&amp; Development Plans</h3>
                        <p>We offer affordable quality website that suites the needs of your business. Checkout our
                            website prices.</p>
                    </div>
                    <div class="row">
                        <div class="col-md-9 mx-auto">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="plan w3-card">
                                        <h4>Single Page Website</h4>
                                        <ul class="">
                                            <li>The best way launch and promote your next new idea.</li>
                                            <li>Providing the most simple way for your clients to contact you.</li>
                                            <li>All in one page</li>
                                        </ul>
                                        <h2><span>From</span>R899</h2>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="plan w3-card">
                                        <h4>Standard Website</h4>
                                        <ul class="">
                                            <li>Allow your client to convert and contextualise your business.</li>
                                            <li>Show what you have to offer and display the breadth of your business.
                                            </li>
                                            <li>Up to 4 pages</li>
                                        </ul>
                                        <h2><span>From</span>R2299</h2>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="plan w3-card">
                                        <h4>eCommerce Website</h4>
                                        <ul class="">
                                            <li>Offer your products or services to your clients online with less setup
                                                and more sales.</li>
                                            <li>With sections for collection, cart, products, menus, payment gateway and
                                                everything that an online store needs.
                                            </li>
                                            <li>Up to 4 pages</li>
                                        </ul>
                                        <h2><span>From</span>R4499</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w3-center">
                        <p>* Add a page from R600. Ts and Cs Apply.</p>
                    </div>
                </div>
            </div>
        </div>

        <newsletter />
    </div>
</template>

<script>
    import PageHeader from "../../components/fragments/PageHeader.vue";
    import Newsletter from "../../components/fragments/Newsletter.vue";
    export default {
        components: {
            PageHeader,
            Newsletter,
        },
    };
</script>