import axios from 'axios'

const url = process.env.NODE_ENV === "development" ? 'http://localhost/work/infinito-group-website/' : 'http://infinitogroup.co.za/'

const postApi = async ( action, data ) => {
    return ( await axios.post( `${ url }back-end/api/api.php?action=${ action }`, data ) ).data
}

const getApi = async ( action, data ) => {
    return (await axios.get(`${url}back-end/api/api.php?action=${action}`, data)).data
}

export {
    postApi,
    getApi
}