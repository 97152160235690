<template>
    <Navigation />
    <router-view />
    <Footer />
</template>
<script>
    import Navigation from "./components/fragments/Navigation.vue";
    import Footer from './components/fragments/Footer'
    export default {
        components: {
            Navigation,
            Footer
        }
    }
</script>