<template>
    <div id="service_page">
        <PageHeader />
        <div class="container">
            <div class="brief-description">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="left-side">
                            <h1>IT Solutions &amp; Services</h1>
                            <h4>
                                Let us find <strong>the right solution</strong>
                                for you.
                            </h4>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="right-side">
                            <div class="services-nav">
                                <ul class="list-unstyled">
                                    <li><button class="btn">Web Hosting &amp; Domain Registration</button>
                                    </li>
                                    <li><button class="btn">Telecoms</button></li>
                                    <li><button class="btn">Network Solutions &amp; Services</button></li>
                                    <li><button class="btn active-btn">IT Solutions &amp; Services</button></li>
                                    <li><button class="btn">Branding</button></li>
                                    <li><button class="btn">Web Design &amp; Development</button></li>

                                    <li><button class="btn">App &amp; Software Development</button></li>

                                    <li><button class="btn">Computer Sales &amp; Repairs</button></li>
                                    <li><button class="btn">Company Registration Packages</button></li>
                                </ul>
                            </div>

                            <router-link to="/contact-us">Connect with us
                                <i class="fas fa-arrow-circle-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="IT-solutions">
                <div class="service-defination">
                    <div class="row">
                        <div class="col-sm-6 w3-center">
                            <img src="../../assets/img/IT-Support.jpg" alt="">
                        </div>
                        <div class="col-sm-6">
                            <h3 class="page-title">Reliable IT Solutions Packages</h3>

                            <p>
                                IT Solutions for healthy workstations; From connectivity to computers and printers, we
                                will look after your business IT needs. We’ll fix your computer problems with the most
                                cost effective IT solutions possible to keep overheads to a minimum.
                            </p>

                            <p>
                                Our aim and intentions for this is to make IT simpler for you. Save money and time with
                                out the need to employ your own IT team.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="service-plans">
                    <div class="plan-heading">
                        <h3>Our IT Solutions Plan</h3>
                        <p>We provide you with quick service response time, full support. Checkout our IT Solutions
                            packege plans.</p>
                    </div>
                    <div class="row">
                        <div class="col-md-10 mx-auto">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="plan w3-card">
                                        <h4>Basic <span>1-10 Workstations</span></h4>
                                        <ul class="">
                                            <li>Pro-active maintenance</li>
                                            <li>Standard Software Installation</li>
                                            <li>Windows Update Management</li>
                                            <li>Up to 2 onsite visits. <em>Ts &amp; Cs apply</em></li>
                                            <li>Up to 5 hours remote support</li>
                                        </ul>
                                        <h2>R699 <span>/month</span></h2>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="plan w3-card">
                                        <h4>Standard <span>11-20 Workstations</span></h4>
                                        <ul class="">
                                            <li>Pro-active maintenance</li>
                                            <li>Standard Software Installation</li>
                                            <li>Windows Update Management</li>
                                            <li>Up to 2 onsite visits. <em>Ts &amp; Cs apply</em></li>
                                            <li>Up to 7 hours remote support</li>
                                        </ul>
                                        <h2>R1299 <span>/month</span></h2>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="plan w3-card">
                                        <h4>Ultimate <span>21-50 Workstations</span></h4>
                                        <ul class="">
                                            <li>Pro-active maintenance</li>
                                            <li>Standard Software Installation</li>
                                            <li>Windows Update Management</li>
                                            <li>Up to 3 onsite visits. <em>Ts &amp; Cs apply</em></li>
                                            <li>Up to 10 hours remote support</li>
                                        </ul>
                                        <h2>R3199 <span>/month</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w3-center">
                        <p>* Each package has a once setup cost.</p>
                        <p>* Workstations refers to computers and printers.</p>
                        <p>* Onsite visits are charged based on the location</p>
                    </div>
                </div>
            </div>
        </div>

        <newsletter />
    </div>
</template>

<script>
    import PageHeader from "../../components/fragments/PageHeader.vue";
    import Newsletter from "../../components/fragments/Newsletter.vue";
    export default {
        components: {
            PageHeader,
            Newsletter,
        },
    };
</script>